<template>
  <div class="z-50 flex h-screen items-center bg-indigo-700">
    <div class="mx-auto px-6 sm:px-6 lg:px-8">
      <vue3-lottie :animation-data="WalkingHippo" :height="150" :width="250" />
      <div class="text-center font-medium text-indigo-100">Please wait a moment</div>
    </div>
  </div>
</template>

<script setup>
import WalkingHippo from "~/assets/images/HippoLoading.json";
</script>
